import ApiService from "@/core/services/api.service";

export default {

    // -----API DATA SE------
    getAllSE(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/surat-edaran/all", { params: credentials })
    },
    getSESingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/surat-edaran/single", { params: credentials })
    },
    verifSE(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/master/surat-edaran", credentials)
    },

    // -----API DATA SE AFKIR DINI------
    getSEAfkirDiniSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/surat-edaran/afkir-dini/single", { params: credentials })
    },

    // -----API DATA SE CUTTING HE------
    getSECuttingHESingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/master/surat-edaran/cutting-he/single", { params: credentials })
    },
}