<template>
  <div data-app>

    <div v-if="dataOri">
      <div v-if="form.rincian.length > 0">
        <div v-for="(rinc, index) in form.rincian" :key="index" class="border rounded p-3 mb-5">
          <div class="d-flex align-items-center mx-3">
            <div class="symbol symbol-35 symbol-light mr-4">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Communication/Group.svg"/>
                  <!--end::Svg Icon-->
                </span> 
              </span>
            </div>         
            <span class="text-dark-75 font-size-h5 font-weight-bolder mr-auto">
                {{ rinc.perusahaan.nama }}
            </span>
          </div>
          
          <!--begin::Separator-->
          <div class="separator separator-solid mt-3 mb-4"></div>
          <!--end::Separator-->

          <div v-for="(mgg, indexMgg) in rinc.minggu" :key="indexMgg" class="align-items-center mx-sm-7 mx-2">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-35 symbol-light mr-4">
                <span class="symbol-label">
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1">
                <a class="text-success text-hover-primary font-size-lg font-weight-bolder">{{ mgg.tgl_awal | dateIndonesia }} s/d {{ mgg.tgl_akhir  | dateIndonesia }}</a>
                <div class="text-muted font-weight-bold">
                  <span v-for="(child, indexChild) in mgg.child" :key="indexChild">
                    {{ (indexChild > 0 ? ', ':'') }} {{ child.sex }} {{ child.jumlah | nominal }} butir
                  </span>
                </div>
              </div>
              <!--end::Text-->
            </div>
            <!--begin::Separator-->
            <div class="separator separator-border-2 separator-light separator-dashed mb-2"></div>
            <!--end::Separator-->
          </div>
        </div>
      </div>
      <div v-else class="border rounded p-3 mb-5 text-center">
        <span class="text-dark-75 font-size-lg font-weight-bolder">Tidak ada data Cutting HE, silahkan tambah data</span>
      </div>

    </div>
    <div v-else>
      <BulletListLoader></BulletListLoader>
    </div>
    <!--end::Form-->

  </div>
</template>

<script>
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VerifikasiService from "@/core/services/api/verifikasi/master/se.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "verifikasi-master-data-surat-edaran-view-cutting-he",
  props: {
    idS: {
      required: true
    }
  },
  components: {
    BulletListLoader
  },  
  data() {
    return {
      dialogForm: false,
      dataOri: null,
      form: {
        se: this.idS,
        rincian: []
      },
      show: true
    }
  },
  methods: {
    getData () {
      VerifikasiService.getSECuttingHESingle({ se: this.idS })
        .then((res) => {
            if (!res.data.status) {
                this.dataOri = JSON.stringify({ se:this.idS, rincian:[] })
                this.$emit('error-show', true)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data
        })
        .catch((err) => {
          this.dataOri = JSON.stringify({ se:this.idS, rincian:[] })
          this.$emit('error-show', true)
          ErrorService.status(err)
        })
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Master Data Surat Edaran" }]);
    this.getData()
  },
};
</script>