<template>
  <div>

    <div v-if="showPage">
      <!--begin::Form-->
        <b-row>
            <b-col cols="12" class="pt-5 pb-0">
                <h6 class="text-muted">Tanggal Afkir Dini</h6>
            </b-col>
            <b-col cols="12" sm="6" class="pt-0 pb-0">
                <b-form-group 
                id="input-group-12" 
                label="Tgl Awal:" 
                label-for="input-12">
                    <span id="input-12" class="form-control form-control-solid pr-0">
                      {{ form.tgl_awal | dateIndonesia }}
                    </span>
                </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" class="pt-0 pb-0">
                <b-form-group 
                id="input-group-2" 
                label="Tgl Akhir:" 
                label-for="input-2">
                    <span id="input-2" class="form-control form-control-solid pr-0">
                      {{ form.tgl_akhir | dateIndonesia }}
                    </span>
                </b-form-group>
            </b-col>
        </b-row>

          <b-row v-for="(rinc, index) in form.rincian" :key="index">
              <b-col cols="10" class="pt-5 pb-0">
                  <h6 class="text-muted">Perusahaan {{index + 1}}</h6>
              </b-col>        
              <b-col cols="12" class="pt-0 pb-0">
                  <b-form-group 
                  :id="`input-group-${index}-3`" 
                  label="Perusahaan:" 
                  :label-for="`input-${index}-3`">
                      <span :id="`input-${index}-3`" class="form-control form-control-solid">
                        {{ rinc.perusahaan.nama }}
                      </span>
                  </b-form-group>
              </b-col>     
              <b-col cols="12" class="pt-0 pb-0">    
                <b-row v-for="(item, key) in rinc.child" :key="key" class="w-full rounded mb-2">
                  <b-col cols="12" md="6" class="pt-0 pb-0">
                      <b-form-group 
                      :id="`input-group-${index}-${key}-3`" 
                      label="Sex:" 
                      :label-for="`input-${index}-${key}-3`">
                          <span :id="`input-${index}-${key}-3`" class="form-control form-control-solid">
                            {{ item.sex }}
                          </span>
                      </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6" class="pt-0 pb-0">
                        <b-form-group 
                        :id="`input-group-${index}-${key}-2`" 
                        label="Jumlah:" 
                        :label-for="`input-${index}-${key}-2`">
                            <span :id="`input-${index}-${key}-2`" class="form-control form-control-solid">
                              {{ item.jumlah | nominal }} ekor
                            </span>
                        </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" class="pt-0 pb-0">
                  <!--begin::Separator-->
                  <div class="separator separator-solid mt-3 mb-4"></div>
                  <!--end::Separator-->
              </b-col>
          </b-row>
      <!--end::Form-->
    </div>
    <div v-else>
      <BulletListLoader></BulletListLoader>
    </div>
  </div>
</template>

<script>
import { BulletListLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VerifikasiService from "@/core/services/api/verifikasi/master/se.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "verifikasi-master-data-surat-edaran-view-afkir-dini",
  props: {
    idS: {
      required: true
    }
  },
  components: {
    BulletListLoader
  },  
  data() {
    return {
      showPage: false,
      dataOri: null,
      form: {
        se: this.idS,
        tgl_awal: null,  
        tgl_akhir: null,  
        rincian: [{
          perusahaan: null,
          child: [{
            sex: null,
            jumlah: 1
          }]
        }]
      },
      show: true
    }
  },
  methods: {   
    getData () {
      VerifikasiService.getSEAfkirDiniSingle({ se: this.idS })
        .then((res) => {
            if (!res.data.status) {
                this.showPage = true
                this.$emit('error-show', true)
                return;
            }
            this.dataOri = JSON.stringify(res.data.data)
            this.form = res.data.data
            this.showPage = true
        })
        .catch((err) => {
          this.$emit('error-show', true)
          this.showPage = true
          ErrorService.status(err)
        })
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Verif Master Data Surat Edaran" }]);
    this.getData()
  },
};
</script>